import PropTypes from "prop-types";
import React from "react";

import LinkArrowBlack from "@/assets/icons/link-arrow-black.svg";
import LinkArrowWhite from "@/assets/icons/link-arrow-white.svg";

const MockInternalCTA = ({
  className,
  isThemeLight = false,
  text,
  url,
  withArrow = false,
}) => {
  const textColor = isThemeLight ? "black" : "white";
  const ctaButton = withArrow ? (
    <button
      className={className}
      name="Buttons:MockInternal:MockInternalCTA - LinkArrow"
      onClick={() => (window.location.href = url)}
      style={{ color: textColor }}
    >
      {text}
      {isThemeLight ? (
        <LinkArrowWhite aria-hidden={true} />
      ) : (
        <LinkArrowBlack aria-hidden={true} />
      )}
    </button>
  ) : (
    <span
      className={className}
      onClick={() => (window.location.href = url)}
      style={{ color: textColor }}
    >
      {text}
    </span>
  );

  return ctaButton;
};

MockInternalCTA.propTypes = {
  className: PropTypes.string.isRequired,
  isThemeLight: PropTypes.bool,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  withArrow: PropTypes.bool,
};

export default MockInternalCTA;
